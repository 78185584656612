:root {
	--black: #333333;
	--primary-color: #06a6f0;
	--primary-tint: #1976d2;
	--primary-shade: #0eacf5;
	--amplify-primary-color: var(--primary-color);
	--amplify-primary-tint: var(--primary-tint);
	--amplify-primary-shade: var(--primary-shade);
	--warning: #f72374;
	--warning-tint: #e00157;
	--warning-shade: #f13c82;
	--dark-blue: #18202c;
}
@import-normalize;

.App {
	height: 100vh;
	width: 100vw;
}
* {
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
	// color: var(--black);
}
h2 {
	font-size: 1.25rem;
	color: var(--black);
}
button {
	appearance: none;
	border: 0;
	outline: 0;
	background-color: transparent;
	cursor: pointer;
	display: flex;
	align-items: center;
	svg {
		margin-right: 0.5rem;
	}
}
